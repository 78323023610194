/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~ngx-toastr/toastr-bs4-alert';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.toast-home {
    top: 70px;
    right: 12px;
}